import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { renderFromProp, renderFromPropWithFallback } from '../index';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const HelloWorld = makeShortcode("HelloWorld");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "renderfromprop"
    }}>{`renderFromProp`}</h2>
    <h3 {...{
      "id": "as-a-function"
    }}>{`As a function`}</h3>
    <Playground __position={0} __code={'() => renderFromProp(() => <div>Hello world</div>)'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">{() => renderFromProp(() => <div>Hello world</div>)}</Playground>
    <h3 {...{
      "id": "as-a-function-with-props"
    }}>{`As a function with props`}</h3>
    <Playground __position={1} __code={'() =>\n  renderFromProp(({ recipient }) => <div>Hello {recipient}</div>, {\n    recipient: \'world\',\n  })'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">{() => renderFromProp(({
        recipient
      }) => <div>Hello {recipient}</div>, {
        recipient: 'world'
      })}</Playground>
    <h3 {...{
      "id": "as-a-function-with-children"
    }}>{`As a function with children`}</h3>
    <Playground __position={2} __code={'() =>\n  renderFromProp(\n    ({ children }) => <div>{children}</div>,\n    {},\n    { children: \'Hello world\' },\n  )'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => renderFromProp(({
        children
      }) => <div>{children}</div>, {}, {
        children: 'Hello world'
      })}
    </Playground>
    <h3 {...{
      "id": "as-a-fragment-with-a-function-with-children"
    }}>{`As a fragment with a function with children`}</h3>
    <Playground __position={3} __code={'() =>\n  renderFromProp(\n    <React.Fragment>\n      {({ children }) => <div>{children}</div>}\n    </React.Fragment>,\n    {},\n    { children: \'Hello world\' },\n  )'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => renderFromProp(<React.Fragment>{({
          children
        }) => <div>{children}</div>}</React.Fragment>, {}, {
        children: 'Hello world'
      })}
    </Playground>
    <h3 {...{
      "id": "as-a-react-node"
    }}>{`As a React node`}</h3>
    <Playground __position={4} __code={'() => {\n  const HelloWorld = () => <div>Hello world</div>\n  return renderFromProp(<HelloWorld />)\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = () => <div>Hello world</div>;

        return renderFromProp(<HelloWorld mdxType="HelloWorld" />);
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-react-node-with-props"
    }}>{`As a React node with props`}</h3>
    <Playground __position={5} __code={'() => {\n  const HelloWorld = ({ recipient }) => <div>Hello {recipient}</div>\n  return renderFromProp(<HelloWorld />, { recipient: \'world\' })\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          recipient
        }) => <div>Hello {recipient}</div>;

        return renderFromProp(<HelloWorld mdxType="HelloWorld" />, {
          recipient: 'world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-react-node-with-children"
    }}>{`As a React node with children`}</h3>
    <Playground __position={6} __code={'() => {\n  const HelloWorld = ({ children }) => <div>{children}</div>\n  return renderFromProp(<HelloWorld />, {}, { children: \'Hello world\' })\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          children
        }) => <div>{children}</div>;

        return renderFromProp(<HelloWorld mdxType="HelloWorld" />, {}, {
          children: 'Hello world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-fragment-with-a-react-node-with-children"
    }}>{`As a fragment with a React node with children`}</h3>
    <Playground __position={7} __code={'() => {\n  const HelloWorld = ({ children }) => <div>{children}</div>\n  return renderFromProp(\n    <React.Fragment>\n      <HelloWorld />\n    </React.Fragment>,\n    {},\n    { children: \'Hello world\' },\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          children
        }) => <div>{children}</div>;

        return renderFromProp(<React.Fragment>
        <HelloWorld mdxType="HelloWorld" />
      </React.Fragment>, {}, {
          children: 'Hello world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-react-element"
    }}>{`As a React element`}</h3>
    <Playground __position={8} __code={'() => {\n  const HelloWorld = () => <div>Hello world</div>\n  return renderFromProp(HelloWorld)\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = () => <div>Hello world</div>;

        return renderFromProp(HelloWorld);
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-react-element-with-props"
    }}>{`As a React element with props`}</h3>
    <Playground __position={9} __code={'() => {\n  const HelloWorld = ({ recipient }) => `Hello ${recipient}`\n  return renderFromProp(HelloWorld, { recipient: \'world\' })\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          recipient
        }) => `Hello ${recipient}`;

        return renderFromProp(HelloWorld, {
          recipient: 'world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-react-element-with-children"
    }}>{`As a React element with children`}</h3>
    <Playground __position={10} __code={'() => {\n  const HelloWorld = ({ children }) => <div>{children}</div>\n  return renderFromProp(HelloWorld, {}, { children: \'Hello world\' })\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          children
        }) => <div>{children}</div>;

        return renderFromProp(HelloWorld, {}, {
          children: 'Hello world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-fragment-with-a-react-element-with-children"
    }}>{`As a fragment with a React element with children`}</h3>
    <Playground __position={11} __code={'() => {\n  const HelloWorld = ({ children }) => <div>{children}</div>\n  return renderFromProp(\n    <React.Fragment>{HelloWorld}</React.Fragment>,\n    {},\n    { children: \'Hello world\' },\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const HelloWorld = ({
          children
        }) => <div>{children}</div>;

        return renderFromProp(<React.Fragment>{HelloWorld}</React.Fragment>, {}, {
          children: 'Hello world'
        });
      }}
    </Playground>
    <h3 {...{
      "id": "as-a-string-with-createfromstring"
    }}>{`As a string with createFromString`}</h3>
    <Playground __position={12} __code={'() =>\n  renderFromProp(\n    \'div\',\n    {},\n    { children: \'Hello world\', createFromString: true },\n  )'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">{() => renderFromProp('div', {}, {
        children: 'Hello world',
        createFromString: true
      })}</Playground>
    <h2 {...{
      "id": "renderfrompropwithfallback"
    }}>{`renderFromPropWithFallback`}</h2>
    <h3 {...{
      "id": "with-a-fallback-string"
    }}>{`With a fallback string`}</h3>
    <Playground __position={13} __code={'() => renderFromPropWithFallback(\'Hello world\')'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">{() => renderFromPropWithFallback('Hello world')}</Playground>
    <h3 {...{
      "id": "as-a-fragment-with-a-fallback-string"
    }}>{`As a fragment with a fallback string`}</h3>
    <Playground __position={14} __code={'() =>\n  renderFromPropWithFallback(<React.Fragment>Hello world</React.Fragment>)'} __scope={{
      props,
      DefaultLayout,
      Playground,
      renderFromProp,
      renderFromPropWithFallback,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">{() => renderFromPropWithFallback(<React.Fragment>Hello world</React.Fragment>)}</Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      